@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
