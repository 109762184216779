.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: gray rgb(3, 3, 61);
}

/* Chrome, Edge, and Safari */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: gray;
    border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(3, 3, 61);
    border-radius: 14px;
    border: 3px solid gray;
}
